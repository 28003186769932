<template>
    <div class="drug-control">
        <div class="drug-control-content">
          <yun-header />
            <div class="drug-control-body">
                <div class="left">
                    <!-- 当前药品管控情况 -->
                    <div class="current-drug">
                        <!-- 标题 -->
                        <div class="current-drug-header">
                            <span> 当前药品管控情况</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="current-drug-echart" ref="currentDrug"></div>
                        <div class="current-drug-status">
                            <span style="background-color:rgb(2 119 250)">提示</span>
                            <span style="background-color: rgb(2 119 250)">提示</span>
                            <span style="background-color:  rgb(2 119 250)">提示</span>
                            <span style="background-color:rgb(244 127 3)">限售</span>
                            <span style="background-color:  rgb(244 127 3)">限售</span>
                        </div>
                    </div>
                    <!-- 违规销售管控药品动态信息 -->
                    <div class="violation-drug">
                        <!-- 标题 -->
                        <div class="violation-drug-header">
                            <span> 违规销售管控药品动态信息</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!-- 表格-->
                        <div class="violation-table">
                            <table>
                                <tr class="first">
                                    <td>序号</td>
                                    <td>违规销售药品</td>
                                    <td style="width:194px">违规药店名称</td>
                                    <td>发生时间</td>
                                    <td>处置状态</td>
                                </tr>
                                <tr class="sconed" v-for="(item, index) in tableList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td style="line-height:30px">{{ item.content }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.time }}</td>
                                    <td>
                                        <span v-if="item.stadus == 1">自动下发</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- 管控药品占比情况   禁售药品分类占比情况 -->
                    <div class="Ofsituation">
                        <!-- 一键锁定 -->
                        <div class="ofsituation-header">
                            <div
                                class="ofsituation-header-arrows"
                                style="cursor: pointer"
                                @click="$refs.childDialog.openControl()"
                            >
                                <span>一键锁定</span>
                                <img src="../assets/control/arrows.png" alt="" />
                            </div>
                        </div>
                        <!-- 图表 -->
                        <div class="ofsituation-content">
                            <div class="left common">
                                <div class="left-echart commonechart" ref="ofsituationleft"></div>
                                <span>管控药品占比情况</span>
                            </div>
                            <div class="center common">
                                <div
                                    class="center-echart commonechart"
                                    ref="ofsituationcenter"
                                ></div>
                                <span>禁售药品分类占比情况</span>
                            </div>
                            <div class="right common">
                                <div class="right-echart">
                                    <div class="right-common">
                                        <span>22%</span>
                                        <span>库存药品禁售率</span>
                                    </div>
<!--                                    <div class="right-common">-->
<!--                                        <span>10.2%</span>-->
<!--                                        <span>医保支付比例</span>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 管控销售趋势分析 -->
                    <div class="sale-trend">
                        <!-- 标题 -->
                        <div class="sale-trend-header">
                            <span> 药品管控管理</span>
                            <img src="../assets/regulation/yd.png" alt="" />
                        </div>
                        <!--本月 本季 本年 -->
                        <div class="sale-timetype">
                            <!-- <div class="time-type">
                                <span class="actived">本月</span>
                                <span>本季</span>
                                <span>本年</span>
                            </div> -->
                        </div>
                        <!-- 图表 -->
                        <div class="sale-trend-echart" ref="saleEchart"></div>
                    </div>
                </div>
            </div>
        </div>
        <yun-dialog ref="childDialog" />
    </div>
</template>

<script>
import Pager from "./components/pager";
import * as echarts from "echarts";
import yunDialog from "@/views/yunDialog";
import {control} from "@/api/yaojian";
import yunHeader from "@/views/yunHeader";
export default {
    components: {
        Pager,
        yunDialog,
      yunHeader
    },
    data() {
        return {
            tableList: [
            ],
            currentDrug: null, //当前药品管控情况
            controlProportion: null, // 管控药品占比
            salesProhibition: null,
            saleTrend: null,
              postControl: {
                page: 1,
                count: 1000,
            },
            controlData:[],
           controlList:[]
        };
    },
    mounted() {
        this.currentDrugEchart();
        this.ofsituationleft();
        this.ofsituationcenter();
        this.saletrendLine();
        this.getControl()
    },
    methods: {
          getControl(data) {
            if (data) {
                this.postControl.page = data.page;
            }
            control(this.postControl).then((rs) => {
                this.controlData = rs.data.list;
              this.controlData=this.$x.uniq(this.controlData, 'name')
              this.controlData=this.controlData.slice(0,5)
                console.log(196,rs.data)
             this.controlData =    this.controlData.map(item=>{
                    return{
                        name:item.name,value:this.filterData(item.names)
                    }
                })

        this.controlData =   this.controlData.filter(item=>item.value!=0)

                this.currentDrugEchart()
                this.controlList=rs.data.list
                console.log( 209,this.controlList);
            });
        },
        filterData(data){
            if(data){
                const res= JSON.parse(data)
                return  res&&res.length

            }else{
                return 0
            }
        },
        //当前药品管控情况
        currentDrugEchart() {
            let that =this
            this.currentDrug = echarts.init(this.$refs.currentDrug);
            const option = {
                grid: {
                    left: "5%",
                    bottom: "-5%",
                    top: 0,
                    width: "80%",
                    containLabel: true,
                },

                xAxis: {
                    show: false,
                    type: "value",
                    axisLabel: {
                   //x轴文字的配置
                    interval: 0,//使x轴文字显示全
           }

                },
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisLabel: {
                            show: true,
                            align: "left",
                            padding: [0, 0, 0, -160],
                            textStyle: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: this.controlData.map(item=>item.name),
                    },
                    {
                        type: "category",
                        inverse: true,
                        axisTick: "none",
                        axisLine: "none",
                        show: true,
                        axisLabel: {
                            align: "left",
                            padding: [0, 20, 20, 0],
                            textStyle: {
                                color: "#fff",
                                fontSize: 24,
                                lineHeight: 18,
                            },
                             interval: 0,
                        },

                        data: this.controlData.map(item=>item.value),
                    },
                ],
                series: [
                    {
                        name: "值",
                        type: "bar",

                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 15,
                                color: "#0277FA",
                            },
                        },
                        barWidth: 15,
                        data:this.controlData.map(item=>item.value),
                    },
                    {
                        name: "背景",
                        type: "bar",
                        barWidth: 15,
                        barGap: "-100%",
                        data: [1500, 1500, 1500, 1500, 1500],
                        itemStyle: {
                            normal: {
                                color: "#071c4c",
                                barBorderRadius: 15,
                            },
                        },
                    },
                ],
            };
            this.currentDrug.setOption(option);

             this.currentDrug.on('click',function({name}){
                console.log(name);
                if(name=="三大类药品限售"){
                  const res=  that.controlList.filter(item=>item.name==name)
                    that.$refs.childDialog.openControl2(res[0])
                }else if(name=="感冒退热药品禁售"){
                     const res=  that.controlList.filter(item=>item.name==name)
                    that.$refs.childDialog.openControl2(res[0])
                }else if(name=="含兴奋剂药品控制"){
                    const res=  that.controlList.filter(item=>item.name==name)
                    that.$refs.childDialog.openControl2(res[0])
                }else if(name=="含兴奋剂药品提示"){
                    const res=  that.controlList.filter(item=>item.name==name)
                    that.$refs.childDialog.openControl2(res[0])
                }else if(name=="含麻黄碱药品限售"){
                     const res=  that.controlList.filter(item=>item.name==name)
                    that.$refs.childDialog.openControl2(res[0])
                }
             })
        },
        //管控药品占比情况
        ofsituationleft() {
            this.controlProportion = echarts.init(this.$refs.ofsituationleft);
            var data = [
                {
                    name: "含兴奋剂药品",
                    value: 22.6,
                },

                {
                    name: "含麻黄碱药品",
                    value: 20.4,
                },
                {
                    name: "不合格",
                    value: 0.8,
                },
            ];
            const option = {
                color: ["#C41DF3", "#FBC902", "#1DCE3A", "#F78306", "#0096FF", "#00D7FE"],
                grid: {},
                legend: {
                    bottom: "8%",
                    left: "45",
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 110,
                                color: "#FEFEFE",
                                fontSize: 18,
                            },
                            b: {},
                        },
                    },
                    itemWidth: 14,
                    itemHeight: 14,
                    itemGap: 18,
                    formatter: (name) => {
                        return `{a|${name}}{b|}`;
                    },
                    orient: "horizontal",
                },
                series: [
                    // 主要展示层的
                    {
                        radius: ["30%", "50%"],
                        center: ["48%", "32%"],
                        type: "pie",
                        label: {
                            normal: {
                                show: true,
                                formatter: "{c}%",
                                textStyle: {
                                    fontSize: 20,
                                    color: "#FEFEFE",
                                },
                                position: "outside",
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        labelLine: {
                            normal: {
                                show: true,
                                length: 6,
                                length2: 6,
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        name: "自助整改率",
                        data: data,
                    },
                ],
            };
            this.controlProportion.setOption(option);
        },
        //禁售药品分类占比情况
        ofsituationcenter() {
            this.salesProhibition = echarts.init(this.$refs.ofsituationcenter);
            let dataList = [
                {
                    name: "管控药品",
                    value: 99,
                },
                {
                    name: "不合格药品",
                    value: 1,
                },
            ];
            const option = {
                tooltip: {
                    trigger: "item",

                    textStyle: {
                        fontWeight: "normal",
                        fontSize: 11,
                    },
                },
                legend: {
                    data: dataList,
                    bottom: "10%",
                    orient: "vertical",

                    left: "10%",
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        fontSize: 20,
                        color: "#fff",
                    },
                    textStyle: {
                        rich: {
                            a: {
                                display: "block",
                                width: 140,
                                fontSize: 20,
                                color: "#fff",
                            },
                            b: {
                                fontSize: 20,
                                color: "#fff",
                            },
                        },
                    },
                    formatter: (name) => {
                        const currentIndex = dataList.findIndex((item) => item.name == name);
                        return `{a|${name}}{b|${dataList[currentIndex].value}%}`;
                    },
                },
                series: [
                    {
                        name: "禁售药品分类占比情况",
                        type: "pie",
                        selectedMode: "single",
                        radius: [0, "35%"],
                        center: ["50%", "30%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 99,
                                name: "管控药品",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 1,
                                name: "不合格药品",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                    {
                        name: "禁售药品分类占比情况",
                        type: "pie",
                        selectedMode: "single",
                        radius: ["40%", "60%"],
                        center: ["50%", "30%"],
                        label: {
                            position: "inner",
                            fontSize: 11,
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            {
                                value: 99,
                                name: "管控药品",
                                itemStyle: {
                                    color: "#0078fa",
                                },
                            },
                            {
                                value: 1,
                                name: "不合格药品",
                                itemStyle: {
                                    color: "#fbc902",
                                },
                            },
                        ],
                    },
                ],
            };
            this.salesProhibition.setOption(option);
        },
        //药品管控管理
        saletrendLine() {
            this.saleTrend = echarts.init(this.$refs.saleEchart);
            const option = {
                color: ["#C41DF3",  "#0277FA", "#FFC038"],
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: "#fff",
                        fontSize: 18,
                    },
                    itemGap: 28,
                    icon: "rect",
                    right: "0%",
                },
                xAxis: {
                    type: "category",
                    data: ["1月", " 2月", "3月", " 4月", "5月","6月"],
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 18,
                        color: "#FEFEFE",
                        fontWeight: 400,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#081d4b",
                        },
                    },
                },
                series: [
                    {
                        name: "含兴奋剂药品",
                        data: [580, 275, 498, 186, 65,46],
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: "#C41DF3",
                        },
                         label: {
                        show: true,
                        position: 'top',
                       textStyle: {
                        color: "#C41DF3",
                       },
                         },
                        areaStyle: {
                            normal: {
                              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "rgba(10,219,250,.3)"
                              },
                                {
                                  offset: 1,
                                  color: "rgba(10,219,250, 0)"
                                }
                              ], false),
                            shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                            shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                          }
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },

                    {
                        name: "含麻黄碱药品",
                        data: [2, 4, 1, 5, 8,30],
                        type: "line",
                        smooth: true,
                        lineStyle: {
                            color: "#FFC038",
                        },
                          label: {
                        show: true,
                        position: 'top',
                       textStyle: {
                        color: "#FFC038",
                       },
                         },
                        areaStyle: {
                             normal: {
                              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "rgba(10,219,250,.3)"
                              },
                                {
                                  offset: 1,
                                  color: "rgba(10,219,250, 0)"
                                }
                              ], false),
                            shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                            shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                          }
                        },
                        labelLine: {
                            lineStyle: {
                                color: "#04559d",
                            },
                        },
                    },
                ],
            };
            this.saleTrend.setOption(option);
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.drug-control {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .drug-control-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .drug-control-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .drug-control-header-back {
                cursor: pointer;
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .drug-control-header-title {
                line-height: 38px;
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .drug-control-body {
            flex: 1;
            display: flex;
            margin-top: 15px;
            padding: 0 35px;
            gap: 20px;
            .left {
                flex: 1;
                .current-drug {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    background-image: url("../assets/control/left-one.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 420px;
                    width: 100%;
                    padding: 20px;
                    .current-drug-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .current-drug-echart {
                        width: 100%;
                        flex: 1;
                    }
                    .current-drug-status {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        right: 31px;
                        top: 57%;
                        transform: translateY(-50%);
                        span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 85px;
                            height: 35px;
                            background: #0277fa;
                            border-radius: 18px;
                            margin-bottom: 30px;
                            font-size: 24px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }
                .violation-drug {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    padding: 20px;
                    height: 524px;
                    background-image: url("../assets/control/left-two.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .violation-drug-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .violation-table {
                        flex: 1;
                        margin-top: 30px;
                        overflow-y: auto;
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            .first {
                                height: 55px;
                                background-image: url("../assets/regulation/表头.png");
                                text-align: center;
                                td {
                                    line-height: 55px;
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                            .sconed {
                                text-align: center;
                                height: 70px;
                                &:nth-child(2n + 1) {
                                    background: url("../assets/regulation/表单.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                }
                                td {
                                    height: 70px;
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #ffffff;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
            .right {
                flex: 1;
                .Ofsituation {
                    display: flex;
                    flex-direction: column;
                    background-image: url("../assets/control/right-one.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 420px;
                    width: 100%;
                    padding: 20px;
                    .ofsituation-header {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .ofsituation-header-arrows {
                            display: flex;
                            align-items: center;
                            span {
                                line-height: 24px;
                                font-size: 24px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                margin-right: 4px;
                            }
                            img {
                                width: 23px;
                                height: 20px;
                            }
                        }
                    }
                    .ofsituation-content {
                        flex: 1;
                        display: flex;
                        .common {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            .commonechart {
                                flex: 1;
                            }
                            .left-echart {
                                width: 345px;
                            }
                            .center-echart {
                                width: 269px;
                                margin-left: 20px;
                            }
                            .right-echart {
                                flex: 1;
                                display: flex;
                                flex-direction: column;

                                .right-common {
                                    width: 220px;
                                    height: 120px;
                                    background-image: url("../assets/control/right-frame.png");
                                    background-size: 100% 100%;
                                    background-repeat: no-repeat;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    margin-bottom: 20px;
                                    span {
                                        &:nth-child(1) {
                                            line-height: 27px;
                                            font-size: 36px;
                                            font-family: Source Han Sans CN;
                                            font-weight: bold;
                                            color: #ffc038;
                                        }
                                        &:nth-child(2) {
                                            line-height: 21px;
                                            font-size: 22px;
                                            font-family: Source Han Sans CN;
                                            font-weight: 400;
                                            color: #ffffff;
                                            margin-top: 15px;
                                        }
                                    }
                                }
                            }
                            span {
                                display: block;
                                height: 22px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #00d7fe;
                                line-height: 26px;
                            }
                        }
                        .right {
                            margin-top: 30px;
                        }
                    }
                }
                .sale-trend {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    height: 524px;
                    background-image: url("../assets/control/left-two.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding: 20px;
                    .sale-trend-header {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 28px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #00d7fe;
                            margin-right: 20px;
                            letter-spacing: 6px;
                        }
                        img {
                            width: 95px;
                            height: 10px;
                        }
                    }
                    .sale-timetype {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .time-type {
                            width: 153px;
                            height: 24px;
                            background: rgba(2, 119, 250, 0);
                            border: 1px solid #0277fa;
                            border-radius: 4px;
                            display: flex;
                            span {
                                flex: 1;
                                height: 100%;
                                border-right: 1px solid #0277fa;
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #eeeeee;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                &:last-child {
                                    border: none;
                                }
                            }
                            .actived {
                                color: #eeeeee;
                                background: #0277fa;
                            }
                        }
                    }
                    .sale-trend-echart {
                        flex: 1;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
</style>
